.menu {
	position: relative;

	.menu-items {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 1000;
		list-style: none;
		background-color: #fff;
		background-clip: padding-box;
		display: none;

		li {
			border-bottom: 1px solid @border-color;

			&:last {
				border: none;
			} 
		}
	}

	&.open {
		.menu-items {
			display: block;
		}
	}


}
