@import "css-lib/less/shared/**/*.less";
@import "css-lib/less/components/core/**/*.less";

@import "lernetz-theme/shared/*.less";
@import "lernetz-theme/*.less";


// reset container border of patternfly.css
.container {
	padding-left: 0px;
	padding-right: 0px;
}

