
body > .container{

	&.login{
		position: relative;
		width: 100%;
		min-height: 550px;
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;
		margin: 35px 0 0 0;
		max-width: initial;
	}
}

.login-title{
	padding: 35px 45px 0 45px;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 48px;
	line-height: 55px;

	@media( max-width:@breakpoint-smartphone ){
		padding: 35px 25px 0 25px;
	}

}

.login-container{

	position: relative;
	top: 25px;

	@media( max-width:@breakpoint-tablet ){
		left: 5%;
		width: 90%;
	}

	@media( max-width:@breakpoint-smartphone ){
		left: 0%;
		width: 100%;
		padding: 25px 25px;
		top: 0px;
	}

	left: 50%;
	width: 45%;
	padding: 25px 50px;
	background-color: #fff;

	#kc-form-buttons {
		margin: 15px 0 15px 0; 
	}

	#kc-form-wrapper {

		.instruction {
			margin: 15px 0px;
		}
	}

	.kc-container{
		margin-top: 15px;

		.alert{
			margin: 10px 0;

			&.alert-error{
				color: @alert;
			}
		}
	}

	.kc-header {
		text-transform: uppercase;
		margin-bottom: 25px;
		font-weight: bold;
		font-size: 25px;
	}

	.kc-label-wrapper{
		margin: 0 0 5px 0;
	}

	.kc-input-wrapper{
		border: 1px solid #eee;
		margin-bottom: 25px;
		
		&.row {
			margin-left:0;
			margin-right: 0;
		}

		input{
			border: none;
			height: 48px;
			padding-left: 10px;
		}
	}

	.input-icon{
		width: 45px;
		height: 45px;
		background-color: #eee;
		padding: 15px;

		.icon {
			width: 14px;
			height: 14px;
			fill:grey;
			transform: rotate(180deg);
		}
	}

	input{
		width: 100%;
		background-color: #fff;
	}

	.re-login{
		padding: 20px 0;
	}

	.social-login {
		
		margin-top: 25px;

		.zocial {
			margin-top: 10px;
			margin-right: 10px;
			float:left;
		}

		ul {
			list-style-type: none;
		}

		>ul >li> a > * {
			display:inline-block;
		}

	}
}


.login-footer{
	min-height: 140px;
	padding: 50px;
	width: 100%;
	background-color: rgba( 245, 245, 245, 1 );
	color: rgba( 74, 74, 74, 1 );
	
	.logo-container {

		text-align:center;

		.logo {
			margin: 10px;
			max-height: 90px;
		}
	}
    
	p{
		line-height: 1.5rem;
	}
}

.kc-locale.menu{
	position: absolute;
	right: 20px;
	top: 20px;
}
