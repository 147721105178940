

.btn {
  background-color: white;
  color: black;
  border: 1px solid @border-color;
  border-radius: 0;
  padding: 12px 16px;
  cursor: pointer;

  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  white-space: nowrap;
  margin: 0;
  box-shadow: 2px 2px 3px 0 rgba(187,187,187,0.3);

  &:hover {
    background-color: white;
    border: 1px solid @selected-color;
    color: @selected-color;

    &.btn--icon .icon {
      border-color: @selected-color;
    }
  }

  &:focus {
    background-color: white;
    border: 1px solid @selected-color;
    color: @selected-color;
    padding-bottom: 11px;
  }

  &.active {
    border-bottom: 2px solid darken( @selected-color, 20% );
    padding-bottom: 11px;
  }

  &.btn--menu-item {
    margin: 0;
    box-shadow: none;
    border: none;
    display: block;

    &:hover {
        background-color: @border-color;
    }
  }


  &.hidden {
    display: none;
  }
}
