/*
---
name: .stretch
category: Shared/Helpers
tag:
 - helpers
---

`.stretch` Define an element which stretches to the size of its parent

```html
<div class="rel" style="height: 55px;">
   <div class="stretch styleguide-grid-unit"> I'm stretched to the size of my parent</div>
</div>
```
*/
.stretch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*
---
name: .abs
category: Shared/Helpers
tag:
 - helpers
---

`.abs` Set the element position to absolute
*/
.abs {
  position: absolute;
}
/*
---
name: .rel
category: Shared/Helpers
tag:
 - helpers
---

`.rel` Set the element position to relative
*/
.rel {
  position: relative;
}
/*
---
name: .vcenter
category: Shared/Helpers
tag:
 - helpers
---

`.vcenter` Sets vertical-align to middle
*/
.vcenter {
  vertical-align: middle;
}
/*
---
name: .hcenter
category: Shared/Helpers
tag:
 - helpers
---

`.hcenter` Sets text-align to center
*/
.hcenter {
  text-align: center;
}
/*
---
name: .floatleft
category: Shared/Helpers
tag:
 - helpers
---

`.floatleft` Sets float to left
*/
.floatleft {
  float: left;
}
/*
---
name: .floatright
category: Shared/Helpers
tag:
 - helpers
---

`.floatright` Sets float to right
*/
.floatright {
  float: right;
}
/*
---
name: .hidden
category: Shared/Helpers
tag:
 - helpers
---

`.hidden` Sets display to none.
*/
.hidden {
  display: none;
}
/*
---
name: rem( pixels )
category: Shared/Mixins
template: mixin
tag:
 - mixin
parameters:
 - name: pixels
   description: The pixel value to convert into rem based on the 16px root font-size
   optional: false
---

This less function turns a pixel value into a rem value based on the root font-size set to 16px.

An example usage looks like this:

```less
.example {
	padding: rem( 15px ) rem( 12px )
}
```
*/
/*
---
name: .for( @array, @code )
category: Shared/Mixins
template: mixin
tag:
 - mixin
parameters:
 - name: array
   description: The array of values to loop over
   optional: false
 - name: code
   description: The code that is executed on every value. You can access the index with `@i` and the value with `@value`
   optional: false
---

This mixin allows to execute code over an array

An example usage looks like this:
```less
@colors: #1abc9c, #2ecc71, #3498db, #9b59b6;

.for(@colors, {
    .color-@{i} {
        color: @value;
    }
});
```

This generates:
```css
.color-1 {
  color: #1abc9c;
}
.color-2 {
  color: #2ecc71;
}
.color-3 {
  color: #3498db;
}
.color-4 {
  color: #9b59b6;
}
```
*/
/*
---
name: Global Reset
category: Shared
---

The lernetz css library has a set of reset style to have the same behaviour on every browser.
Furthermore this reset sets some global variables like:
 * body font-families
 * body line-height
 * body text-rendering
 * :root font-size for proper rem conversion
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: inherit;
  vertical-align: baseline;
}
:root {
  font-size: 16px;
}
html {
  font-size: 100%;
}
body {
  line-height: 1.5rem;
  color: #3F3F40;
  font-family: 'Helvetica', sans-serif;
  text-rendering: optimizeLegibility;
  background-color: #FFF;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
select {
  vertical-align: middle;
}
textarea {
  width: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/*
 * Normalize the behaviour of all browsers
 *
 * Instead of setting box-sizing fix on all elements we only set it on the root element
 * and tell all elements to inherit it's property from the parent
 * That way it's possible to overwrite the box-sizing anywhere while keeping proper inheritance
 */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
a {
  text-decoration: none;
  color: #EC6449;
}
a > * {
  display: block;
}
/*
---
name: Colors
category: Shared/Variables
template: colors
tag:
 - variables
 - colors
colors:
 - variable: "@text-color"
   description: "The default text color on the body"
   value: "#3F3F40"
 - variable: "@text-inverted-color"
   description: "The default inverted text color on the body"
   value: "#FFF"
 - variable: "@action-color"
   description: "The main action color used for links and buttons"
   value: "#EC6449"
 - variable: "@body-color"
   description: "The main background color"
   value: "#FFF"
---

The color variables that are used throughout the library. 
*/
/*
---
name: Global settings
category: Shared/Variables
template: variables
tag:
 - variables
variables:
 - name: "@text-line-height"
   description: "The line height of the body"
   default: "1.5rem"
 - name: "@font-family"
   description: "The font-family of the body"
   default: "'Helvetica', sans-serif"
 - name: "@text-rendering"
   description: "The text-rendering of the body"
   default: "optimizeLegibility"
 - name: "@transition-duration"
   description: "The duration of the slide transitions"
   default: "0.4s"
---

These variables setups some global text formatting and styling.
*/
/*
---
name: Size settings
category: Shared/Variables
template: variables
tag:
 - variables
 - grid
variables:
 - name: "@grid-gutter"
   description: "The space between the grid children"
   default: "rem( 30px )"
 - name: "@grid-break-points"
   description: "The screensize when the grid should break."
   default: "rem(600px), rem(1024px)"
 - name: "@grid-break-labels"
   description: "The breakpoint label corresponding to the entie in the `@grid-break-points` list"
   default: "medium, large"
 - name: "@page-padding"
   description: "The padding for the .page-fluid component"
   default: "rem( 60px )"
---

These variables setups some global sizes mainly for the grid.
*/
/*
---
name: .button-default
category: Buttons/Default
tag:
 - component
 - button
---

The default button sets some basic properties so it is working as a `button` or `a` tag.

An example for a button:
```jade
button.button-default My Button
```

An example for a link:
```jade
a.button-default My Link
```
*/
.button-default {
  appearance: none;
  background: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  outline: none;
}
/*
---
name: .grid
category: Layout/Grid
tag:
 - component
 - responsive
compile: true
---

A simple grid system that wraps children nodes based on the viewport width.

## Simple side by side rendering

The easiest use case is to use simple `.col-1`, `.col-1-2`, `.col-1-3`, `.col-1-4` for a side-by-side rendering.
The default value for all child elements of a `.grid` is equivalent to `.col-1`, so for only one child it will span the full width.

```jade
div.grid
	div.col-1-2 
		div.aigis-debug col-1-2
	div.col-1-2
		div.aigis-debug col-1-2
```
```jade
div.grid
	div.col-1-4 
		div.aigis-debug col-1-4
	div.col-3-4
		div.aigis-debug col-3-4
```

## Layouts on different devices

The breakpoints for different screen sizes are specified in the `@grid-break-points`, `@grid-break-labels` variables.
The default values define two breakpoints with labels: `.medium-X-X` and `.large-X-X`.
So an example grid with two breakpoints looks like this:

```jade
div.grid
	div.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1 medium-1-2 large-1-4
	div.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1 medium-1-2 large-1-4
	div.col-1-2.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1-2 medium-1-2 large-1-4
	div.col-1-2.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1-2 medium-1-2 large-1-4
```

**Note:** The `col-1` classes are not present on the first two nodes, since this is the default behaviour.


## Reverse order

To reverse the order in which the columns are displayed, you can add the modifier class `.-reverse` to your grid.
The columns will be in DOM order when viewport switches to small layout.

```jade
div.grid.-reverse
	div.col-1-4
		div.aigis-debug First column in DOM
	div.col-1-4
		div.aigis-debug Second column in DOM
	div.col-1-4
		div.aigis-debug Third column in DOM
	div.col-1-4
		div.aigis-debug Fourth column in DOM
```
## No gutter

If you dont need a spacing/gutter between the grid columns you can use the modifier `.-nogutter`.

```jade
div.grid.-nogutter
	div.col-1-4
		div.aigis-debug First column in DOM
	div.col-1-4
		div.aigis-debug Second column in DOM
	div.col-1-4
		div.aigis-debug Third column in DOM
	div.col-1-4
		div.aigis-debug Fourth column in DOM
```
*/
.grid {
  margin: 0 -0.9375rem;
  /**
	 * Generate all media breakpoints based on the @grid-break-points, @grid-break-labels variables.
	 */
}
.grid:after {
  content: "";
  display: table;
  clear: both;
}
.grid > * {
  float: left;
  position: relative;
  width: 100%;
  padding: 0 0.9375rem;
}
.grid.-reverse > * {
  float: right;
}
.grid.-nogutter {
  margin: 0;
}
.grid.-nogutter > * {
  padding: 0;
}
.grid .col-1 {
  width: 100%;
}
.grid .col-1-2 {
  width: 50%;
}
.grid .col-1-3 {
  width: 33.33333333%;
}
.grid .col-2-3 {
  width: 66.66666667%;
}
.grid .col-1-4 {
  width: 25%;
}
.grid .col-3-4 {
  width: 75%;
}
@media all and (min-width: 37.5rem) {
  .grid > .medium-1 {
    width: 100%;
  }
  .grid > .medium-1-2 {
    width: 50%;
  }
  .grid > .medium-1-3 {
    width: 33.33333333%;
  }
  .grid > .medium-2-3 {
    width: 66.66666667%;
  }
  .grid > .medium-1-4 {
    width: 25%;
  }
  .grid > .medium-3-4 {
    width: 75%;
  }
}
@media all and (min-width: 64rem) {
  .grid > .large-1 {
    width: 100%;
  }
  .grid > .large-1-2 {
    width: 50%;
  }
  .grid > .large-1-3 {
    width: 33.33333333%;
  }
  .grid > .large-2-3 {
    width: 66.66666667%;
  }
  .grid > .large-1-4 {
    width: 25%;
  }
  .grid > .large-3-4 {
    width: 75%;
  }
}
/*
---
name: img.responsive
category: Layout/Image
tag:
 - responsive
compile: true
---

This class makes the image scaling to its max width.

```jade
img.responsive(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```
*/
img.responsive {
  max-width: 100%;
  height: auto;
}
/*
---
name: .media-responsive
category: Layout/Media
tag:
 - responsive
compile: true
---

`.media-responsive` - This makes a the `.media-responsive` div a container for any media element (iframe, video, ... ) that responsive scales with a fix ratio.
The media element ( child ) needs the class `.stretch` to fully fill out the parents size.

The `.media-responsive` component has to predefined modifiers: `.-media-4-3`, `.-media-16-9` that define a fix ratio.

```jade
div.media-responsive.-media-16-9
	iframe.stretch(src="https://www.youtube.com/watch?v=TCd6PfxOy0Y")
```
*/
.media-responsive {
  width: 100%;
  position: relative;
  padding-bottom: 50%;
}
.media-responsive.-media-4-3 {
  padding-bottom: 75%;
}
.media-responsive.-media-16-9 {
  padding-bottom: 56.26%;
}
/*
---
name: .page-fluid
category: Page/Fluid
tag:
 - responsive
compile: true
---

`.page-fluid` centers the page content and adds a padding so the page sticks not to the browser viewport.
Furthermore it has a max-width based on the last `@grid-break-points` value.

```jade
div.page-fluid
	div.aigis-debug Some page content
```

## Max-width page

To broaden the width of the page you can add the modifier `.-unlimited`. Thsi will span the page to the full width.

```jade
div.page-fluid.-unlimited
	div.aigis-debug Some page content
```
*/
.page-fluid {
  max-width: 71.5rem;
  margin: auto;
  padding: 0 3.75rem;
}
.page-fluid.-unlimited {
  max-width: none;
}
/*
---
name: .row
category: Layout/Row
tag:
 - component
compile: true
---

This styling allow to place containers in a row. They will **not** break as the `.grid` but resize them accordingly.
Some benefits of the `.row` approach is the easy vertical centering and the usage of the `.col-auto` class.

## Basic row with vertical centering

You can use the sizing classes `.col-X-X` to define the width of a column as the following example shows. 

```jade
div.row
	div.col-1-4.aigis-debug col-1-4 with a lot of text that spans the column.
	div.col-3-4.aigis-debug col-3-4 content.
```

## Fixed row

There is a `.-fixed` modifier where each column has the same width.

```jade
div.row.-fixed
	div.aigis-debug evenly spreadout column
	div.aigis-debug evenly spreadout column with more content
	div.aigis-debug evenly spreadout column
	div.aigis-debug evenly spreadout column with much more content than the other
	div.aigis-debug.vcenter evenly spreadout column with .vcenter
```


## Auto sized column width and vcenter

There is a special `.col-auto` class that resizes the column to the size of its content.
This is usefull for example if you have a fixed size logo and the rest of the header has to resize.

```jade
div.row.-center
	div.col-auto.aigis-debug
		img(src="http://placehold.it/100x100")
	div.col-1.aigis-debug The rest
	div.col-auto.aigis-debug
		img(src="http://placehold.it/200x100")
```
*/
.row {
  display: table;
  width: 100%;
}
.row.-fixed {
  table-layout: fixed;
}
.row.-center > * {
  vertical-align: middle;
}
.row.-bottom > * {
  vertical-align: bottom;
}
.row.-padded > * + * {
  padding-left: 1.875rem;
}
.row > * {
  display: table-cell;
}
.row > *.col-1 {
  width: 100%;
}
.row > *.col-1-2,
.row > *.col-2-4 {
  width: 50%;
}
.row > *.col-1-3 {
  width: 33.33333333%;
}
.row > *.col-2-3 {
  width: 66.66666667%;
}
.row > *.col-1-4 {
  width: 25%;
}
.row > *.col-3-4 {
  width: 75%;
}
.row > *.col-auto {
  width: auto;
  white-space: nowrap;
}
.btn {
  background-color: white;
  color: black;
  border: 1px solid #E0E0E0;
  border-radius: 0;
  padding: 12px 16px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  white-space: nowrap;
  margin: 0;
  box-shadow: 2px 2px 3px 0 rgba(187, 187, 187, 0.3);
}
.btn:hover {
  background-color: white;
  border: 1px solid #006699;
  color: #006699;
}
.btn:hover.btn--icon .icon {
  border-color: #006699;
}
.btn:focus {
  background-color: white;
  border: 1px solid #006699;
  color: #006699;
  padding-bottom: 11px;
}
.btn.active {
  border-bottom: 2px solid #002233;
  padding-bottom: 11px;
}
.btn.btn--menu-item {
  margin: 0;
  box-shadow: none;
  border: none;
  display: block;
}
.btn.btn--menu-item:hover {
  background-color: #E0E0E0;
}
.btn.hidden {
  display: none;
}
body > .container.login {
  position: relative;
  width: 100%;
  min-height: 550px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  margin: 35px 0 0 0;
  max-width: initial;
}
.login-title {
  padding: 35px 45px 0 45px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 48px;
  line-height: 55px;
}
@media (max-width: 420px) {
  .login-title {
    padding: 35px 25px 0 25px;
  }
}
.login-container {
  position: relative;
  top: 25px;
  left: 50%;
  width: 45%;
  padding: 25px 50px;
  background-color: #fff;
}
@media (max-width: 756px) {
  .login-container {
    left: 5%;
    width: 90%;
  }
}
@media (max-width: 420px) {
  .login-container {
    left: 0%;
    width: 100%;
    padding: 25px 25px;
    top: 0px;
  }
}
.login-container #kc-form-buttons {
  margin: 15px 0 15px 0;
}
.login-container #kc-form-wrapper .instruction {
  margin: 15px 0px;
}
.login-container .kc-container {
  margin-top: 15px;
}
.login-container .kc-container .alert {
  margin: 10px 0;
}
.login-container .kc-container .alert.alert-error {
  color: #e2001a;
}
.login-container .kc-header {
  text-transform: uppercase;
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 25px;
}
.login-container .kc-label-wrapper {
  margin: 0 0 5px 0;
}
.login-container .kc-input-wrapper {
  border: 1px solid #eee;
  margin-bottom: 25px;
}
.login-container .kc-input-wrapper.row {
  margin-left: 0;
  margin-right: 0;
}
.login-container .kc-input-wrapper input {
  border: none;
  height: 48px;
  padding-left: 10px;
}
.login-container .input-icon {
  width: 45px;
  height: 45px;
  background-color: #eee;
  padding: 15px;
}
.login-container .input-icon .icon {
  width: 14px;
  height: 14px;
  fill: grey;
  transform: rotate(180deg);
}
.login-container input {
  width: 100%;
  background-color: #fff;
}
.login-container .re-login {
  padding: 20px 0;
}
.login-container .social-login {
  margin-top: 25px;
}
.login-container .social-login .zocial {
  margin-top: 10px;
  margin-right: 10px;
  float: left;
}
.login-container .social-login ul {
  list-style-type: none;
}
.login-container .social-login > ul > li > a > * {
  display: inline-block;
}
.login-footer {
  min-height: 140px;
  padding: 50px;
  width: 100%;
  background-color: #f5f5f5;
  color: #4a4a4a;
}
.login-footer .logo-container {
  text-align: center;
}
.login-footer .logo-container .logo {
  margin: 10px;
  max-height: 90px;
}
.login-footer p {
  line-height: 1.5rem;
}
.kc-locale.menu {
  position: absolute;
  right: 20px;
  top: 20px;
}
.menu {
  position: relative;
}
.menu .menu-items {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  display: none;
}
.menu .menu-items li {
  border-bottom: 1px solid #E0E0E0;
}
.menu .menu-items li:last {
  border: none;
}
.menu.open .menu-items {
  display: block;
}
.container {
  padding-left: 0px;
  padding-right: 0px;
}
